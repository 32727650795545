import React, { useEffect, useState, useRef } from "react";
import { NextArrow, CopySection } from "@molecules";
import loadable from "@loadable/component";
import { Container, Thumbprint } from "@atoms";
import { useScroll } from "framer-motion";
import classNames from "classnames";

const GrowingImage = loadable(() => import("@organisms/GrowingImage"));

const GrowingImageCopy = ({ heading, subheading, copy, image }) => {
  const ref = useRef(null);
  const [fade, setFade] = useState(0);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end 0.75"],
  });

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange(v => {
      setFade(v > 0);
    });
    return () => {
      unsubscribe();
    };
  }, [ref.current]);

  return (
    <>
      <GrowingImage heading={heading} image={image} hideImage={fade}>
        <div className="min-h-[75vh]">
          <Container frame className="sticky top-[33vh]">
            <CopySection
              twoColumns
              subheading={subheading}
              copy={copy}
              className={classNames("relative z-10 transition duration-1000", {
                "text-transparent": fade,
                "text-white": !fade,
              })}
            />
            <NextArrow className="relative z-10 mt-14" />
            <div
              className={classNames(
                "pointer-events-none absolute top-0 z-0 h-screen w-screen transition duration-300",
                {
                  "opacity-0": !fade,
                }
              )}
            >
              <Thumbprint progress={scrollYProgress} />
            </div>
          </Container>
        </div>
      </GrowingImage>
      <div
        ref={ref}
        className="absolute left-0 right-0 h-[50vh] w-full -translate-y-[150%]"
      />
    </>
  );
};

export default GrowingImageCopy;
